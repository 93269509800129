<template>
  <v-container fluid>
    <loading :active.sync="loading" />
    <v-card raised>
      <v-card-text v-if="showScreen" class="nospace">
        <jobs-list-view v-on:job-load="load" :updateList="updateTable" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { EventBus } from "../../event-bus.js";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import JobsListView from "./ProcurementList";
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import moment from "moment";
import Loading from "vue-loading-overlay";
export default {
  mixins: [checkPermissionsMixin],
  beforeCreate: function () {},

  created() {
    //  this.checkPermission("procurement");

    if (localStorage.getItem("first-load")) {
      localStorage.removeItem("first-load");
    }
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
    });
    this.$eventBus.$on("procurement", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      if (this.workingCountry.includes(value.workingCountry)) {
        this.updateTable = this.updateTable + 1;
      }
    });
    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }
  },
  components: {
    JobsListView,
    JobsProfile,
    Loading,
  },
  data: () => ({
    active: "0",
    dialog: false,
    showScreen: true,
    loading: true,
    updateTable: 0,
  }),
  methods: {
    checkPermission(operation) {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    load(event) {
      this.loading = event;
    },
    getDefaultProfile() {
      return Object.assign(
        {},
        {
          jobId: "NA",
          waybillNumber: "",
          from: null,
          to: null,
          amount: null,
          notes: null,
          driverName: null,
          waybillNumber: null,
          status: null,
          customerName: null,
          customerCurrency: null,
          currentStatus: null,
          assignmentsRequired: null,
          timeZoneId: null,
          startDate: null,
          deliveryDate: null,
          otherDetails: null,
          packingType: null,
          cargoWeight: null,
          quantity: null,
          sourceCity: null,
          sourceCountry: null,
          destinationCity: null,
          destinationCountry: null,
          natureOfCargo: null,
          orderType: null,
          truckType: null,
          status: -2,
        }
      );
    },
  },
};
</script>

<style></style>
