<template>
  <div>
    <loading :active.sync="loading" />
    <v-card class
      ><v-layout>
        <v-flex xs3>
          <v-tabs
            style="margin-bottom: 20px"
            v-model="tabStatusMain"
            fixed-tabs
            background-color="darkorange"
            dark
            class="main__tabs"
          >
            <v-tab :href="`#Jobs`" @click="redirectTo('Jobs')">
              Job View
            </v-tab>
            <v-tab :href="`#Assignment`" @click="redirectTo('Assignment')">
              Assignment View
            </v-tab>
          </v-tabs>
        </v-flex>

        <v-flex xs9>
          <v-tabs
            style="margin-bottom: 20px"
            v-model="tabStatus"
            fixed-tabs
            background-color="darkorange"
            dark
          >
            <v-tab
              :href="`#Unassigned`"
              @click="searchData('Unassigned', null)"
            >
              Unassigned Assignments
              <span v-if="newUnassigned" class="pd-left">
                ({{ newUnassigned }})</span
              >
            </v-tab>
            <v-tab
              :href="`#In-progress`"
              @click="searchData('In-progress', null)"
            >
              In Progress Assignments
              <span v-if="newInprogress" class="pd-left">
                ({{ newInprogress }})</span
              >
            </v-tab>
            <v-tab :href="`#Completed`" @click="searchData('Completed', null)">
              Completed Assignments
              <span v-if="newCompleted" class="pd-left">
                ({{ newCompleted }})</span
              >
            </v-tab>
          </v-tabs>
        </v-flex>
      </v-layout>

      <v-card-title class="bg-clr"> </v-card-title>

      <v-container fluid filter_wrap>
        <v-layout row pt-0>
          <v-flex xs2>
            <v-autocomplete
              v-model="searchTypes"
              :items="searchItems"
              label="Search Type"
              item-text="status"
              single-line
              item-value="abbr"
              persistent-hint
              @change="searchData()"
              return-object
            ></v-autocomplete>
          </v-flex>
          <v-divider class="divider-style" vertical></v-divider>
          <v-flex xs2 md3 pr-1>
            <v-text-field
              class="gap searchAccounts"
              label="Search here.."
              :disabled="!searchTypes"
              single-line
              v-model="search"
              @keyup.enter="searchData()"
              @click:append="searchData()"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-autocomplete
              v-model="driverSearchTypes"
              :items="driverSearchItems"
              label="Search  Type"
              item-text="status"
              single-line
              item-value="abbr"
              persistent-hint
              @change="searchData()"
              return-object
            ></v-autocomplete>
          </v-flex>
          <v-divider class="divider-style" vertical></v-divider>
          <v-flex xs2 md3 pr-3>
            <v-text-field
              class="gap searchAccounts"
              label="Search here.."
              :disabled="!driverSearchTypes"
              single-line
              v-model="driverSearch"
              @keyup.enter="searchData()"
              @click:append="searchData()"
              append-icon="search"
            ></v-text-field>
          </v-flex>

          <v-flex xs2 pr-2 v-if="this.tabStatus != 'Unassigned'">
            <v-autocomplete
              label="Assign Status"
              :items="statusList"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="assignStatus"
              @change="searchData()"
              item-text="text"
              item-value="value"
              single-line
            />
          </v-flex>
          <v-flex xs2 pr-2 v-if="this.tabStatus != 'Unassigned'">
            <v-autocomplete
              label="Adv. Status"
              :items="advanceStatusList"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="advanceStatus"
              @change="searchData()"
              item-text="text"
              item-value="value"
              single-line
            />
          </v-flex>

          <v-flex xs4 pt-3 pr-3 pl-1 id="jobDate" class="wrapper date_9">
            <ejs-daterangepicker
              :placeholder="waterMark"
              v-model="dateRange2"
            ></ejs-daterangepicker>
          </v-flex>
          <v-flex xs1 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                flat
                class="reset-btn-color"
                style="padding: 1px !important"
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
              </v-btn>
            </v-card-actions>
          </v-flex>
          <v-flex xs1 style="width: 5px" pl-3 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                flat
                class="reset-btn-color mx-2"
                prepend-icon
                color="orange darken-1"
                @click="filter_toggle()"
              >
                <v-icon color="orange">filter_alt</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container fluid filter_data_toggle id="filter-toggle">
        <v-layout row pt-0>
          <v-flex xs2 pr-2 pt-0>
            <v-select
              v-model="subJobType"
              :items="JobTypes"
              label="Job Type"
              style="padding-top: 0px !important"
              persistent-hint
              return-object
              @change="searchData()"
              single-line
            ></v-select>
          </v-flex>

          <v-flex
            xs2
            pr-2
            style="padding: 0px"
            pt-0
            v-if="subJobType.value === 'landFreight'"
          >
            <v-autocomplete
              label="Truck Type"
              :items="subTruck"
              class="pt-0"
              style="padding-top: 0px !important"
              v-model="subTruckType"
              @change="searchData()"
              item-text="text"
              item-value="value"
              single-line
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-text class="nospace">
        <v-data-table
          id="jobsTable"
          hide-actions
          :headers="headers"
          :disable-initial-sort="false"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <td style="position: relative">
              <v-layout row>
                <v-flex
                  xs12
                  md3
                  class="blink__icon"
                  v-if="
                    !props.item.isAcknowedged &&
                    props.item.assignmentStatus != -1
                  "
                >
                  <v-tooltip
                    right
                    class="milestone"
                    content-class="tooltip-op"
                    color="orange"
                  >
                    <template slot="activator">
                      <svg>
                        <circle
                          fill="#FF0000"
                          stroke="none"
                          cx="30"
                          cy="30"
                          r="6"
                        >
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.1"
                          />
                        </circle>
                      </svg>
                    </template>

                    <span class="tooltip" v-if="activeMenu == 13"
                      >Please acknowledge the job from the job view to assign
                      driver to the assignment and proceed further.
                    </span>
                    <span class="tooltip" v-else
                      >Please acknowledge the job from the Procurement's job
                      view to assign driver to the assignment and proceed
                      further.
                    </span>
                  </v-tooltip>
                </v-flex>
                <activityLogs
                  :type="'assignment'"
                  :permissions="'activity-logs-jobs'"
                  :_id="props.item._id"
                  :id="props.item.assignmentId.toString()"
                />

                <span style="padding-top: 4px !important; padding-left: 3px">
                  {{ props.item.jobId }}
                </span>
              </v-layout>
              {{ getTime3(props.item.pickupDate) }}
              {{ props.item.pickupTime }}
            </td>
            <td>
              <span
                style="color: blue"
                v-if="props.item.assignmentType == 'Value Added Service'"
              >
                {{ props.item.assignmentId }}</span
              >
              <span v-else> {{ props.item.assignmentId }}</span
              ><br />{{ assignmentStatus(props.item.assignmentStatus) || "-" }}
            </td>
            <td>
              {{ props.item.customer || props.item.companyName }} <br />{{
                props.item.customerPhoneNumber && props.item.customerCountryCode
                  ? props.item.customerCountryCode +
                    "-" +
                    props.item.customerPhoneNumber.replace(
                      props.item.customerCountryCode,
                      ""
                    )
                  : props.item.customerPhoneNumber
              }}<CustomerDetailPopUp :detail="props.item" />
            </td>
            <td style=" min-width: 150px; !important">
              <span v-if="props.item.driverData">
                {{
                  props.item.driverData.firstName +
                  " " +
                  props.item.driverData.lastName
                }}<br />{{
                  props.item.driverData.countryCode +
                  "-" +
                  props.item.driverData.phoneNo
                }}</span
              >
              <span
                v-if="
                  (!props.item.driverData &&
                    props.item.assignmentStatus === -1) ||
                  !props.item.isAcknowedged
                "
                >N.A</span
              >

              <ViewDetails
                v-if="props.item.driverData"
                :detail="props.item.driverData"
                :headers="driverDetailHeaders"
                :title="'Driver'"
              />
              <div
                v-if="
                  !props.item.hasVasAttached &&
                  props.item.assignmentType == 'Normal'
                "
              >
                <button
                  type="button"
                  class="track-ass-btn red-button mt-2"
                  v-permissions="'unassign-driver-jobs'"
                  v-if="
                    (!props.item.isGenerated &&
                      props.item.assignmentStatus === 2 &&
                      props.item.totalAdditionalAmount == 0) ||
                    (props.item.assignmentStatus === 5 &&
                      props.item.totalAdditionalAmount == 0 &&
                      !props.item.isGenerated)
                  "
                  @click="openDriverUnassign(props.item)"
                >
                  Unassign
                </button>
              </div>
              <span
                v-if="
                  props.item.finalPaymentWithCreditDaysDriver &&
                  props.item.assignmentStatus != 4
                "
                class="font_Bold"
              >
                <span
                  v-if="
                    getDays(props.item.finalPaymentWithCreditDaysDriver) > 5
                  "
                  class="orange-text"
                >
                  Credit Days:
                  {{ getDays(props.item.finalPaymentWithCreditDaysDriver) }}
                </span>
                <span v-else class="red-text">
                  Credit Days:
                  {{ getDays(props.item.finalPaymentWithCreditDaysDriver) }}
                </span>
              </span>

              <JobsProfileDriverAssign
                v-if="
                  props.item.isAcknowedged &&
                  props.item.jobType == 1 &&
                  !props.item.isGenerated &&
                  props.item.assignmentStatus === 1
                "
                :milestoneId="props.item.milestoneId"
                :assignmentId="props.item.assignmentId"
                :assignmentDetail="props.item"
                :jobStatus="props.item.jobStatus"
                @transporter-success="refresh()"
                :currency="props.item.customerCurrency"
                :country="props.item.workingCountry"
                @refresh-assign-list="refresh()"
                :type="2"
              />
              <jobProfileDriverAssignAir
                v-if="props.item.isAcknowedged && props.item.jobType == 2"
                :milestoneId="props.item.milestoneId"
                :assignmentDetail="props.item"
                :jobStatus="props.item.jobStatus"
                :assignmentId="props.item.assignmentId"
                :country="props.item.workingCountry"
                :currency="props.item.customerCurrency"
                :type="2"
                @refresh-assign-list="refresh()"
              />
              <jobProfileDriverAssignSea
                v-if="props.item.isAcknowedged && props.item.jobType == 3"
                :assignmentDetail="props.item"
                :jobStatus="props.item.jobStatus"
                :milestoneId="props.item.milestoneId"
                :currency="props.item.customerCurrency"
                :country="props.item.workingCountry"
                :assignmentId="props.item.assignmentId"
                :type="2"
                @refresh-assign-list="refresh()"
              />
            </td>
            <td>
              <div v-if="props.item.transporterData">
                {{ props.item.transporterData.transporterName || "-" }}<br />{{
                  props.item.transporterData.countryCode +
                  "-" +
                  props.item.transporterData.phoneNo
                }}
              </div>
              <div v-else>N.A</div>
              <ViewDetails
                v-if="props.item.transporterData"
                :detail="props.item.transporterData"
                :headers="transporterDetailHeaders"
                :title="'Transporter'"
              />
              <v-flex>
                <div
                  v-if="
                    !props.item.hasVasAttached &&
                    props.item.assignmentType == 'Normal'
                  "
                >
                  <button
                    type="button"
                    class="track-ass-btn red-button mt-2"
                    v-permissions="'unassign-driver-jobs'"
                    v-if="
                      props.item.assignedToTransporter &&
                      !props.item.isGenerated &&
                      ((props.item.totalAdditionalAmount == 0 &&
                        props.item.assignmentStatus === 1) ||
                        (props.item.totalAdditionalAmount == 0 &&
                          props.item.assignmentStatus === 2) ||
                        (props.item.totalAdditionalAmount == 0 &&
                          props.item.assignmentStatus === 5))
                    "
                    :disabled="props.item.jobStatus === 10"
                    @click="openTransporterUnassign(props.item)"
                  >
                    Unassign
                  </button>
                </div>
              </v-flex>
            </td>
            <td>
              <v-flex v-if="props.item.driverData">
                <v-tooltip
                  right
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator" v-if="props.item.truckNumber">
                    {{ props.item.truckPrefix }}-{{
                      props.item.truckNumber
                    }}</template
                  >
                  <template slot="activator" v-else>
                    {{ props.item.driverData.truckPrefix }}-{{
                      props.item.driverData.truckNumber
                    }}</template
                  >
                  <span class="tooltip" v-if="props.item.jobType === '1'"
                    >{{ getText(TruckTypesList, props.item.truckType) }}
                  </span>

                  <span class="tooltip" v-if="props.item.jobType === '2'">
                    {{
                      getText(airTypes, props.item.truckType.toString())
                    }}</span
                  >
                  <span class="tooltip" v-if="props.item.jobType === '3'">
                    {{
                      getText(seaTypes, props.item.truckType.toString())
                    }}</span
                  >
                </v-tooltip>
              </v-flex>

              <v-flex v-else> N.A </v-flex>
            </td>
            <td v-if="props.item.paymentAmount">
              {{ props.item.paymentAmount }} {{ props.item.customerCurrency }}
            </td>
            <td v-else>N.A</td>
            <td v-if="props.item.jobData.isJobActivatedByScript">N.A</td>
            <td v-else>
              <v-layout row justify-center>
                <activityLogs
                  :permissions="'activity-logs-for-accounts'"
                  class="pd_5"
                  :type="'jobs'"
                  v-if="
                    props.item.advanceAmount || props.item.everAdvanceGenerated
                  "
                  :subType="5"
                  :assignmentId="props.item._id"
                  :whichId="'Assignment'"
                  :_id="props.item.jobData._id"
                  :id="props.item.assignmentId.toString()"
                  :section="'advance'"
                />

                <span class="pd-t-l" v-if="props.item.advanceAmount"
                  >{{ props.item.advanceAmount }}
                  {{ props.item.customerCurrency }}</span
                >
              </v-layout>
              <!-- {{ props.item.driverData.ownerType.toString() }}
              {{
                props.item.driverData.ownerType
                  .toString()
                  .includes("Transporter")
              }} -->
              <GenerateAdvance
                :assId="props.item.assignmentId"
                v-permissions="'generate-advance-jobs'"
                :status="props.item.assignmentStatus"
                :currency="props.item.customerCurrency"
                :additionalCharges="props.item.totalAdditionalAmount"
                :type="'1'"
                :driverCreditDuration="
                  props.item.driverData
                    ? props.item.driverData.driverPaymentDuration
                    : null
                "
                :assignedToTransporter="props.item.assignedToTransporter"
                :ownerType="props.item.ownerType"
                :invoiceCity="props.item.invoicingCity"
                :invoiceCompany="props.item.invoicingCountry"
                :totaladvancePaid="props.item.totalAdvancePaidAmount"
                :maximumAdvanceLeftAmount="props.item.maximumAdvanceLeftAmount"
                @generateAdvance="refresh()"
                @assignment-list-refresh="refresh()"
                :totalAmount="props.item.paymentAmount"
                :totalAdvanceAmount="props.item.advanceAmount"
              />

              <generateAdvancePopup
                v-if="props.item.assignmentStatus > 1"
                :assignId="props.item._id"
                :advancePaid="props.item.totalAdvancePaidAmount"
                :jobCurrency="props.item.customerCurrency"
                :additionalCharges="props.item.totalAdditionalAmount"
                :status="props.item.assignmentStatus"
                @assignment-list-refresh="refresh()"
              />
              <span
                v-if="
                  props.item.assignmentStatus <= 1 && !props.item.advanceAmount
                "
                >N.A</span
              >
            </td>
            <td>
              <span v-if="props.item.totalAdvancePaidAmount"
                >{{ props.item.totalAdvancePaidAmount }}
                {{ props.item.customerCurrency }}</span
              >
              <span v-else>N.A</span>
            </td>
            <td v-if="props.item.jobData.isJobActivatedByScript">N.A</td>
            <td v-else style=" min-width: 150px; !important">
              <span v-if="props.item.totalMiscellaneousChargeAmount">
                Misc:&nbsp;{{ props.item.totalMiscellaneousChargeAmount }}
                {{ props.item.customerCurrency }}</span
              >
              <br v-if="props.item.totalAdditionalAmount" />
              <span v-if="props.item.totalAdditionalAmount">
                Driver:&nbsp;{{ props.item.totalAdditionalAmount }}
                {{ props.item.customerCurrency }}</span
              ><br v-if="props.item.totalCostMarginAdditionalAmount" />
              <span v-if="props.item.totalCostMarginAdditionalAmount">
                Customer:&nbsp;{{ props.item.totalCostMarginAdditionalAmount }}
                {{ props.item.customerCurrency }}</span
              >
              <additionalChargesPopup
                v-if="props.item.assignmentStatus > 1"
                :isAssign="props.item._id"
                :jobCurrency="props.item.customerCurrency"
                :jobSalesInvoice="props.item.jobData.isSalesInvoiceGenerated"
                :amountPaidToDriver="props.item.amountPaidToDriver"
                :assignSalesInvoice="props.item.isSalesInvoiceGenerated"
                :isCustomerReceiptInitiate="
                  props.item.isCustomerReceiptInitiated
                "
                @assignment-list-refresh="refresh()"
              />
              <span v-if="props.item.assignmentStatus <= 1">N.A</span>
            </td>
            <td>
              <v-layout justify-center>
                <v-flex xs3 v-if="props.item.isReadyForFinalPayment">
                  <activityLogs
                    v-if="props.item.isReadyForFinalPayment"
                    class="pd_5"
                    :permissions="'activity-logs-for-accounts'"
                    :type="'assignment'"
                    :subType="7"
                    :assignmentId="props.item._id"
                    :_id="props.item.jobData._id"
                    :whichId="'Assignment'"
                    :id="props.item.assignmentId.toString()"
                    :section="'advance'"
                  />
                </v-flex>
                <span
                  class="pd_8"
                  v-if="
                    !props.item.isPurchaseInvoiceGenerated &&
                    props.item.workingCountry === 'PAK' &&
                    props.item.assignmentStatus >= 3 &&
                    props.item.assignmentStatus != -1 &&
                    props.item.assignmentStatus != 4 &&
                    props.item.assignmentStatus != 7
                  "
                >
                  {{ getAdvanceAmount(props.item) }}</span
                >
                <span
                  class="pd_8"
                  v-else-if="
                    props.item.isPurchaseInvoiceGenerated &&
                    props.item.assignmentStatus >= 2 &&
                    props.item.assignmentStatus != -1 &&
                    props.item.assignmentStatus != 7 &&
                    props.item.assignmentStatus != 4
                  "
                >
                  {{ getAdvanceAmount(props.item) }}
                </span>

                <!-- <span
                  class="pd_8"
                  v-if="
                    props.item.isPurchaseInvoiceGenerated &&
                    props.item.assignmentStatus >= 2 &&
                    props.item.assignmentStatus != -1 &&
                    props.item.assignmentStatus != 4 &&
                    props.item.amountPaidToDriver
                  "
                >
                  {{
                    props.item.paymentAmount -
                      props.item.totalAdvancePaidAmount -
                      props.item.amountPaidToDriver +
                      props.item.totalAdditionalAmount ==
                    0
                      ? " "
                      : props.item.paymentAmount -
                        props.item.totalAdvancePaidAmount -
                        props.item.amountPaidToDriver +
                        props.item.totalAdditionalAmount
                  }}
                  {{
                    props.item.paymentAmount -
                      props.item.totalAdvancePaidAmount -
                      props.item.amountPaidToDriver +
                      props.item.totalAdditionalAmount ==
                    0
                      ? ""
                      : props.item.customerCurrency
                  }}</span
                >

                <span
                  class="pd_8"
                  v-else-if="
                    props.item.isPurchaseInvoiceGenerated &&
                    props.item.assignmentStatus >= 2 &&
                    props.item.assignmentStatus != -1 &&
                    props.item.assignmentStatus != 4
                  "
                >
                  {{
                    props.item.paymentAmount -
                      props.item.totalAdvancePaidAmount +
                      props.item.totalAdditionalAmount ==
                    0
                      ? " "
                      : props.item.paymentAmount -
                        props.item.totalAdvancePaidAmount +
                        props.item.totalAdditionalAmount
                  }}
                  {{
                    props.item.paymentAmount -
                      props.item.totalAdvancePaidAmount +
                      props.item.totalAdditionalAmount ==
                    0
                      ? ""
                      : props.item.customerCurrency
                  }}</span
                > -->
              </v-layout>
              <v-layout>
                <AddPhysicalDoc
                  :assignDetails="props.item"
                  :section="'assignmentList'"
                  :driverCreditDuration="
                    props.item.driverData
                      ? props.item.driverData.driverPaymentDuration
                      : null
                  "
                  @ready-to-pay="refresh()"
                />
              </v-layout>
              <v-layout class="pb-1 pt-1" align-center justify-center>
                <EditPhysicalDoc
                  v-if="!props.item.amountPaidToDriver"
                  :assignDetails="props.item"
                  :section="'assignmentList'"
                  :driverCreditDuration="
                    props.item.driverData
                      ? props.item.driverData.driverPaymentDuration
                      : null
                  "
                  @ready-to-pay="refresh()"
                />

                <PaymentDone
                  :section="'Assignment'"
                  :confirmationTitle="'Comments'"
                  :details="props.item"
                  :cancelBtn="'Cancel'"
                  @close="error = 'Kindly collect the gps device'"
                  :PendingAmount="getPendingAmountForProps(props.item)"
                  :assignId="props.item._id"
                  :confirmBtn="'Pay'"
                  @refresh-list="refresh()"
                />
                <v-flex
                  v-if="
                    props.item.assignmentStatus != 4 &&
                    props.item.assignmentStatus == 3 &&
                    getPendingAmountForProps(props.item) > 0 &&
                    props.item.amountPaidToDriver
                  "
                  style="padding-right: 10px !important"
                >
                  <PendingAmountList
                    :type="'PO'"
                    :PendingAmount="getPendingAmountForProps(props.item)"
                    :assignId="props.item._id"
                    :currency="props.item.customerCurrency"
                    @assignment-list-refresh="refresh()"
                  />
                </v-flex>
              </v-layout>
              <button
                v-if="props.item.isReadyForFinalPayment"
                class="track-assign-btn blue-button"
                flat
                prepend-icon
                color="white darken-1"
                @click.stop="goToProof(props.item._id)"
              >
                View Docs
              </button>
              <span
                v-if="
                  !getAdvanceAmount(props.item) &&
                  props.item.assignmentStatus !== 3 &&
                  props.item.assignmentStatus !== 6 &&
                  props.item.assignmentStatus !== 4 &&
                  props.item.assignmentStatus !== 7
                "
              >
                N.A
              </span>
            </td>
            <td>
              <div v-if="props.item.LastCommentTime">
                <v-tooltip
                  left
                  class="milestone"
                  content-class="tooltip-op-comment"
                  color="orange"
                >
                  <template slot="activator">
                    {{
                      getTime4(props.item.LastCommentTime.updatedAt)
                    }}</template
                  >
                  <span
                    class="tooltip"
                    style="word-break: break-all !important"
                    >{{
                      props.item.LastCommentTime.comment != " "
                        ? props.item.LastCommentTime.comment
                        : "N.A"
                    }}</span
                  >
                </v-tooltip>
              </div>
              <SendComments
                v-if="props.item.isAcknowedged"
                :userId="props.item.userId"
                :userName="props.item.customer"
                :detail="props.item"
                :type="3"
                :assignId="props.item._id"
                v-on:pagination-load="refreshOnComment"
                :section="'assignments'"
                :id="props.item.jobId"
              />
              <span v-if="!props.item.isAcknowedged">N.A</span>
            </td>

            <td>
              <v-layout justify-center>
                <generateAdvancePopup
                  v-if="
                    props.item.assignmentStatus > 1 &&
                    props.item.assignmentStatus != 4
                  "
                  :assignId="props.item._id"
                  :isVisible="props.item.totalIssuedAmount"
                  :section="'ReadyToPay'"
                  :advancePaid="props.item.totalAdvancePaidAmount"
                  :additionalCharges="props.item.totalAdditionalAmount"
                  :jobCurrency="props.item.customerCurrency"
                  @assignment-list-refresh="refresh()"
                />
              </v-layout>
              <v-layout justify-center>
                <v-icon
                  v-if="props.item.isAcknowedged"
                  @click="
                    goToDoc(
                      props.item.jobId,
                      props.item.assignmentId,
                      props.item._id
                    )
                  "
                  color="blue"
                >
                  description
                </v-icon>

                <button
                  v-if="props.item.isAcknowedged"
                  v-permissions="'view-details-jobs'"
                  :disabled="loading"
                  @click="check(props.item.jobId)"
                  target="_blank"
                  color="orange"
                  outline
                  class="pay-ass-btn"
                >
                  Details
                </button>
              </v-layout>
              <v-layout justify-center>
                <div v-if="!props.item.hasVasAttached">
                  <button
                    v-permissions="'cancel-assignment-jobs'"
                    v-if="
                      !props.item.isGenerated &&
                      props.item.isAcknowedged &&
                      ((props.item.assignmentStatus >= 0 &&
                        props.item.assignmentStatus <= 2) ||
                        props.item.assignmentStatus == 5 ||
                        props.item.assignmentStatus == 8)
                    "
                    class="pay-ass-btn button-red"
                    @click="checkCancel(props.item.assignmentId)"
                  >
                    Cancel
                  </button>
                </div>
              </v-layout>
            </td>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :componentKey="componentKey"
          :type="0"
          :url="paginationURL"
          :callback="callback"
          :action="`assignment`"
          v-on:pagination-load="checkLoading"
        />
        <v-dialog
          v-if="confirmationDialog.cancelAssignment"
          v-model="confirmationDialog.cancelAssignment"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title dark dense flat style="background-color: #ffc04c">
              <div class="headline white--text">
                Confirmation of cancellation
              </div>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-flex pt-2 pr-1>
                  <div class="subheading muted caption">
                    Provide cancellation reason
                  </div>
                  <v-select
                    class="pt-0 currencyTitle"
                    v-model="cancelType"
                    :items="cancellationType"
                    label="Select your option"
                    persistent-hint
                    :menu-props="{ offsetY: true }"
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
                <div
                  v-if="cancelType.value == 'Other’s'"
                  class="subheading muted caption"
                >
                  Reason
                </div>
                <v-text-field
                  class="pt-0 currencyTitle"
                  v-if="cancelType.value == 'Other’s'"
                  type="text"
                  single-line
                  label="Enter your reason"
                  required
                  v-model="cancellationReason"
                  :value="cancellationReason"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-layout>
                <v-btn
                  color="primary darken-1"
                  text
                  @click.native="open3"
                  :loading="loading"
                  >Yes</v-btn
                >
                <v-btn color="grey" @click.native="closeCancelAssignment" text
                  >Cancel</v-btn
                >
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog5"
          persistent
          :max-width="450"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar
              dark
              style="
                background-color: #fee9d0;
                color: black;
                padding-left: 100px;
              "
              flat
            >
              <v-toolbar-title>{{ cancelTitle1 }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon
                  class="heading grey--text text--darken-4"
                  @click.prevent="
                    dialog5 = false;
                    cancelType = '';
                    cancellationReason = null;
                  "
                  >close</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-spacer class="spacerclass">
              <v-card-text
                style="text-align: center"
                v-show="!!cancelMessage1"
                class="pa-4"
              >
                <div class="pb-4">
                  <img
                    src="../../assets/Character-sitting.png"
                    height="300"
                    width="300"
                  />
                </div>
                <div class="subheading">{{ cancelMessage1 }}</div>
                <div
                  v-if="cancelType.value == 'Other’s'"
                  class="subheading muted pt-2"
                >
                  <span style="color: darkgrey">Cancellation reason:</span>
                  <p style="word-break: break-all">
                    {{ cancelType.value }} - {{ cancellationReason }}
                  </p>
                </div>
                <div v-else class="subheading muted pt-2">
                  <span style="color: darkgrey">Cancellation reason:</span>
                  {{ cancelType.value }}
                </div>
              </v-card-text>

              <v-card-actions class="pt-2 pb-5" style="justify-content: center">
                <v-btn
                  medium
                  style="text-transform: none; !important"
                  color="orange white--text "
                  darken-1
                  :loading="loading"
                  text
                  @click.native="cancelAssignment"
                  >DONE</v-btn
                >
              </v-card-actions>
            </v-spacer>
          </v-card>
        </v-dialog>
        <confirmation-dialog
          :content="`Are you sure to remove transporter from this assignment?`"
          :toShow="confirmationDialog.removeTransporterAssignment"
          :onClose="() => remove()"
          confirmTitle="Unassign"
          :loading="loading"
          :error="error"
          :onConfirm="() => removeTransporterAssignment(this.assignmentData)"
        />
        <confirmation-dialog
          :content="`Are you sure to remove ${
            this.assignmentData.driverData
              ? this.assignmentData.driverData.name
              : ''
          } from this assignment?`"
          :toShow="confirmationDialog.removeDriverAssignment"
          :onClose="removeDriver"
          confirmTitle="Unassign"
          :loading="loading"
          :error="error"
          :onConfirm="() => removeDriverAssignment(this.assignmentData)"
        />
        <confirmation-dialog
          :content="`Have you collected the physical document from the driver ?`"
          :onClose="() => removeReadyToPay()"
          :title="`Ready to Pay Final Payment`"
          :toShow="confirmationDialog.readyToPay"
          :type="1"
          confirmTitle="Yes"
          :loading="loading"
          :error="error"
          :onConfirm="() => removeTransporterAssignment(this.assignmentData)"
        />
      </v-card-text>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");

import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import PaymentDone from "@/components/Accounts/Popups/PaymentDone.vue";
import Loading from "vue-loading-overlay";
import additionalChargesPopup from "@/components/JobDocuments/additionalChargesPopup";
import generateAdvancePopup from "@/components/JobDocuments/generateAdvancePopup";
import SendComments from "@/components/Pop-ups/SendComments";
import ViewDetails from "@/components/Pop-ups/ViewDetails";
import CustomerDetailPopUp from "../Customer/CustomerDetailPopUp";
import PendingAmountList from "@/components/Accounts/Popups/PendingAmountList.vue";
import JobsProfileDriverAssign from "@/components/Jobs/AssignDriver/JobsProfileDriverAssign";
import jobProfileDriverAssignAir from "@/components/Jobs/AssignDriver/jobProfileDriverAssignAir";
import jobProfileDriverAssignSea from "@/components/Jobs/AssignDriver/jobProfileDriverAssignSea";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
Vue.use(DateRangePickerPlugin);
import activityLogs from "../Common/activityLogs";
import { EventBus } from "../../event-bus.js";
let momentTz = require("moment-timezone");
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import pagination from "@/components/Pagination/pagination";
import AddPhysicalDoc from "@/components/Pop-ups/AddPhysicalDoc";
import EditPhysicalDoc from "@/components/Pop-ups/EditPhysicalDoc";

import {
  StorageKeys,
  JobListTypes,
  CancellationType,
  TruckTypes,
  SeaTypes,
  AirTypes,
} from "../../constants/constants";
import { assignmentList } from "@/constants/datatable-headers.js";
import { async } from "q";
export default {
  created() {
    this.getSearchList();
    this.cancellationType = CancellationType;
    this.userId = localStorage.getItem("userId");
    this.JobTypes = JobListTypes;
    this.subTruck = TruckTypes;
    this.TruckTypesList = TruckTypes;

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      if (localStorage.getItem("TabStatusAssign") != null) {
        this.tabStatus = localStorage.getItem("TabStatusAssign");
        this.getsearchTypes(localStorage.getItem("TabStatusAssign"));
      } else {
        this.tabStatus = "Unassigned";
        this.getsearchTypes("Unassigned");
      }

      this.workingCountry = JSON.stringify(workingCountry);
      this.paginationURL = `/dmsAdmin/AssignmentListingView?workingCountry=${this.workingCountry}&isSearch=true&status=${this.tabStatus}`;
      this.getListCount();
    }
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "assignments") {
        this.workingCountry = JSON.stringify(val);
        if (localStorage.getItem("paginationUrlAssignment") !== null) {
          this.updatePaginationUrl();
          this.getListCount();
        } else {
          this.paginationURL = `/dmsAdmin/AssignmentListingView?workingCountry=${this.workingCountry}&isSearch=true&status=${this.tabStatus}`;
          this.getListCount();
        }
      }
    });
    this.$eventBus.$on("assignments", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }

      if (this.workingCountry.includes(value.workingCountry)) {
        this.componentKey = this.componentKey + 1;
        this.getListCount();
      }
    });
    this.$eventBus.$on("additionalchargeAdd", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }

      if (this.workingCountry.includes(value.workingCountry)) {
        this.componentKey = this.componentKey + 1;
        this.getListCount();
      }
    });
    this.$eventBus.$on("job-details-purchase-invoice", (data) => {
      this.componentKey = this.componentKey + 1;
    });
  },

  mounted() {
    if (localStorage.getItem("TabStatusAssign") != null) {
      this.tabStatus = localStorage.getItem("TabStatusAssign");
      this.getsearchTypes(localStorage.getItem("TabStatusAssign"));
      this.getListCount();
    } else {
      this.tabStatus = "Unassigned";
      this.getsearchTypes("Unassigned");
      this.getListCount();
    }
  },

  components: {
    pagination,
    PaginationButtons,
    JobsProfileDriverAssign,
    jobProfileDriverAssignSea,
    PendingAmountList,
    jobProfileDriverAssignAir,
    activityLogs,
    AddPhysicalDoc,
    EditPhysicalDoc,
    CustomerDetailPopUp,
    SendComments,
    additionalChargesPopup,
    ProofOfDelivery,
    generateAdvancePopup,
    Loading,
    GenerateAdvance,
    ConfirmationDialog,
    PaymentDone,
    ViewDetails,
  },
  data() {
    return {
      componentKey: 0,
      cancelTitle1: null,
      activeMenu: localStorage.getItem("activeMenu"),
      cancelMessage1: null,
      waterMark: "Select a Range",
      dateRange: { startDate: "", endDate: "" },
      startDate: "",
      endDate: "",
      airTypes: AirTypes,
      seaTypes: SeaTypes,
      dateRange2: null,
      cancellationType: [],
      options: {
        color: "#ffc04c",
        width: 450,
        zIndex: 200,
      },
      cancelType: "",
      userId: null,
      assignmentData: {},
      driverDetailHeaders: {
        id: "ID",
        name: "Name",
        phone: "Phone No.",
      },
      transporterDetailHeaders: {
        id: "ID",
        name: "Name",
        email: "Email",
        phone: "Phone No.",
      },
      selectCountryDialog: false,
      isDashboard: true,
      searchTypes: "",
      search: "",
      error: null,
      subTruck: [],
      TruckTypesList: [],
      subTruckType: "",
      advanceStatusList: [],
      assignID: null,
      advanceStatus: "",
      confirmationDialog: {
        cancelAssignment: false,
        makePayment: false,
        completeAssignment: false,
        removeDriverAssignment: false,
        removeTransporterAssignment: false,
        readyToPay: false,
      },
      JobTypes: [],
      subJobType: "",
      waterMark: "Select a Range",
      dateRange2: null,
      dateRange: { startDate: "", endDate: "" },
      workingCountry: null,
      JobListTypes: [],
      pages: 1,
      searchItems: [],
      driverSearchItems: [],
      driverSearch: "",
      driverSearchTypes: "",

      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
      // ----- NOTIFICATION ---
      newUnassigned: 0,
      newInprogress: 0,
      newCompleted: 0,
      x: {
        error: null,
      },
      dialog5: false,
      currentEnquiry: null,
      currentIndex: null,
      paginationURL: ``,

      tabStatus: "",
      tabStatusMain: "Assignment",

      totalInquiry: 0,
      totalDriverAssigned: 0,
      totalFindingDrivers: 0,
      inactiveJob: 0,
      completedJob: 0,
      totalJob: 0,
      totalPages: 0,
      statusList: [],
      statusListInProgress: [],

      statusListCompleted: [],

      assignStatus: "",
      jobStatus: "",
      pagination: {},
      isNoSearchResult: false,
      loading: true,
      server: false,
      headers: assignmentList,
      items: [],
    };
  },
  props: {
    updateList: Number,
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        this.searchData(null, val);
      } else {
        this.startDate = "";
        this.endDate = "";

        this.searchData(null, val);
      }
    },

    pagination(val) {
      let temp_url = "";
      if (val["sortBy"] === "createdDate") {
        if (val["descending"] === true) {
          temp_url = "&toSort=date&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=date&sortBy=descending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (val["sortBy"] === "quote") {
        if (val["descending"] === true) {
          temp_url = "&toSort=price&sortBy=descending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=price&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (!val["sortBy"]) {
        this.removeParam(["toSort", "sortBy"]);
      }
      this.updateQueryStringParameter("isSearch", "true");
      deep: true;
    },
  },
  methods: {
    getAdvanceAmount(data) {
      let pendingAmount = this.getPendingAmountForProps(data, true);
      return `${pendingAmount}  ${data.customerCurrency}`;
    },
    getPendingAmountForProps(data, isDecimal = false) {
      let amountPaidToDriver = data.amountPaidToDriver || 0;
      let adjustAmount = data.amountAdjustedFromThisAssignment || 0;
      let totalAdvance =
        data.paymentAmount -
        (data.totalAdvancePaidAmount - adjustAmount) -
        amountPaidToDriver +
        data.totalAdditionalAmount;

      if (isDecimal) return totalAdvance.toFixed(2);
      else return Number(totalAdvance.toFixed(2));
    },
    refreshOnComment() {
      this.componentKey++;
    },
    getSearchList() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      const url = `${this.constants.apiUrl}/dmsAdmin/searchListArray`;
      this.axios.get(url, config).then((response) => {
        this.statusListInProgress = response.data.data.assignStatusInProgress;
        this.statusListCompleted = response.data.data.assignStatusCompleted;
        this.advanceStatusList = response.data.data.AdvanceStatusList;
        if (localStorage.getItem("TabStatusAssign") != null) {
          this.tabStatus = localStorage.getItem("TabStatusAssign");
          if (this.tabStatus == "In-progress") {
            this.statusList = this.statusListInProgress;
          } else {
            this.statusList = this.statusListCompleted;
          }
        }
      });
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },
    getTime5(date) {
      return moment(date).format("LT");
    },
    getDays(day) {
      let finalDate = moment(day);
      // console.log(moment(day).format("ll HH:mm"));
      let dayToday = moment(new Date());

      // let finalDriverDay = moment(finalDate - dayToday).format("E");

      // return finalDriverDay;
    },
    goToDoc(jobId, assignId, _id) {
      this.$router.push(`/documents/${_id}`);
    },
    goToProof(_id) {
      this.$router.push(`/documents/${_id}?type=Driver_Proof`);
    },
    getDays(day) {
      let finalDate = moment(day);
      let dayToday = moment(new Date());
      let remainingDay = finalDate.diff(dayToday, "hours");
      return Math.round(remainingDay / 24);
    },
    cancelAssignment() {
      this.loading = true;
      let url = "/dmsAdmin/cancelAssignment";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        assignmentId: this.assignID.toString(),
      };
      if (this.cancelType.value) {
        if (this.cancelType.value == "Other’s") {
          data.cancellationReason =
            this.cancelType.value + " - " + this.cancellationReason;
        } else {
          data.cancellationReason = this.cancelType.value;
        }
      }

      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          this.$eventBus.$emit("cancelled");
          this.loading = false;
          this.dialog5 = false;
          this.status = -1;
          this.componentKey = this.componentKey + 1;
          this.closeCancelAssignment();
        },
        (error) => {
          this.loading = false;
          this.error = this.$eventBus.parse(error);
        }
      );
    },
    closeCancelAssignment() {
      this.cancellationReason = null;
      this.confirmationDialog.cancelAssignment = false;
      this.cancelType = "";
    },
    open3(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (!this.cancelType) {
        this.error = "Please select cancellation reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
      if (this.cancelType.value == "Other’s") {
        if (!this.cancellationReason) {
          this.error = "Please enter cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return false;
        }
      }
      this.confirmationDialog.cancelAssignment = false;
      this.dialog5 = true;
      this.cancelTitle1 = "Cancellation Message";
      this.cancelMessage1 = "Your job has been cancelled!";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancel3() {
      this.resolve(false);
      this.dialog5 = false;
    },
    async checkCancel(id) {
      this.operationName = "cancel-assignment-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.assignID = id;
        this.confirmationDialog.cancelAssignment = true;
      } else {
        return;
      }
    },
    redirectTo(type) {
      if (type === "Jobs") {
        if (localStorage.getItem("activeMenu") == 13) {
          this.$router.push(`/procurements`);
        } else {
          this.$router.push(`/jobs`);
        }
      } else {
        this.$router.push(`/assignments`);
      }
    },
    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);
      return finalEl ? finalEl.text : "NA";
    },
    async check(jobId) {
      this.loading = true;
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/jobs/${jobId}`);
      } else {
        this.loading = false;
        return;
      }
    },
    getListCount() {
      let v = {};
      this.loading = true;
      let Url = "/dmsAdmin/accountAssignmentDashboard";
      let temp_url = this.paginationURL;
      Url = "/dmsAdmin/AssignmentListingViewCount?" + temp_url.split("?")[1];
      let body = {};
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;
      this.newUnassigned = 0;
      this.newInprogress = 0;
      this.newCompleted = 0;
      this.totalJob = 0;
      this.totalPages = 0;
      v = this.axios.get(this.constants.apiUrl + Url, body).then(
        (response) => {
          this.loading = false;
          let countData = response.data.data;
          if (!this.search.trim().length) {
            localStorage.removeItem("searchAssign");
          }
          if (!this.driverSearch.trim().length) {
            localStorage.removeItem("searchAssignDriver");
          }
          this.newUnassigned = countData.upcomingCount;
          this.newInprogress = countData.ongoingCount;
          this.newCompleted = countData.completedCount;
          this.totalJob = countData.countData;
          this.totalPages = Math.ceil(this.totalJob / 15);
        },
        (error) => {}
      );

      return v;
    },
    async callback({ jobInfo, totalData }) {
      this.items = jobInfo;
      this.pagination.rowsPerPage = 15;
      this.$emit("job-load", false);
      window.scrollTo(0, 0);
      this.loading = false;
    },
    refresh() {
      this.componentKey = this.componentKey + 1;
    },
    openTransporterUnassign(assignData) {
      this.confirmationDialog.removeTransporterAssignment = true;
      this.assignmentData = { ...assignData };
    },
    openReadyToPay() {
      this.confirmationDialog.readyToPay = true;
    },
    openDriverUnassign(assignData) {
      this.confirmationDialog.removeDriverAssignment = true;
      this.assignmentData = { ...assignData };
    },
    removeDriver() {
      this.confirmationDialog.removeDriverAssignment = false;
    },
    remove() {
      this.confirmationDialog.removeTransporterAssignment = false;
    },
    removeReadyToPay() {
      this.confirmationDialog.readyToPay = false;
    },
    removeTransporterAssignment(data) {
      if (
        data.assignmentStatus == 2 ||
        data.assignmentStatus == 5 ||
        data.assignmentStatus == 6
      ) {
        this.error = "Please unassign the driver first !";
        this.loading = false;
        return;
      }
      this.loading = true;
      let url = "/dmsAdmin/unassignTransporter";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.confirmationDialog.removeTransporterAssignment = false;
          this.componentKey = this.componentKey + 1;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },
    removeDriverAssignment(data) {
      if (!data.driverData) {
        return;
      }
      let url = "/dmsAdmin/removeDriver";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        driverId: data.driverData.driverId.toString(),
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.componentKey = this.componentKey + 1;
          this.confirmationDialog.removeDriverAssignment = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },

    readyToPay() {},

    updateQueryStringParameter(key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = this.paginationURL.indexOf("?") !== -1 ? "&" : "?";
      if (this.paginationURL.match(re)) {
        this.paginationURL = this.paginationURL.replace(
          re,
          "$1" + key + "=" + value + "$2"
        );
      } else {
        this.paginationURL = this.paginationURL + separator + key + "=" + value;
      }
    },

    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlAssignment");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");
      this.paginationURL = tempUrl;
    },

    page(event) {
      this.pages = event;
    },

    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime(time) {
      return moment.unix(time).format("ll");
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    getsearchTypes(tabStatus) {
      if (tabStatus === "In-progress" || "Completed") {
        if (tabStatus === "In-progress") {
          this.statusList = this.statusListInProgress;
        } else {
          this.statusList = this.statusListCompleted;
        }
        this.searchItems = this.driverSearchItems = [
          {
            status: "Company/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "driverId" },
          {
            status: "Driver Name",
            abbr: "driverName",
          },
          { status: "Truck Number", abbr: "truckNumber" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];
      }
      if (tabStatus === "Unassigned") {
        this.driverSearchItems = this.searchItems = [
          {
            status: "Company/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];
      }
    },
    getsearchTypeList(tabStatus) {
      if (this.searchTypes.abbr) {
        if (tabStatus === "In-progress" || "Completed") {
          this.driverSearchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "driverId" },
            {
              status: "Driver Name",
              abbr: "driverName",
            },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
        }
        if (tabStatus === "Unassigned") {
          this.driverSearchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
        }
        this.driverSearchItems.forEach((k) => {
          if (k["abbr"] == this.searchTypes.abbr) {
            let elementPos = this.driverSearchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.driverSearchItems.splice(elementPos, 1);
          }
        });
      }
      if (this.driverSearchTypes.abbr) {
        if (tabStatus === "In-progress" || "Completed") {
          this.searchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "driverId" },
            {
              status: "Driver Name",
              abbr: "driverName",
            },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
        }
        if (tabStatus === "Unassigned") {
          this.searchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
        }
        this.searchItems.forEach((k) => {
          if (k["abbr"] == this.driverSearchTypes.abbr) {
            let elementPos = this.searchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchItems.splice(elementPos, 1);
          }
        });
      }
    },
    searchTabStatus(jobStatusVal, url) {
      if (jobStatusVal) {
        localStorage.setItem("TabStatusAssign", jobStatusVal);
        if (jobStatusVal === "In-progress" || "Completed") {
          this.assignStatus = "";
          if (jobStatusVal === "In-progress") {
            this.statusList = this.statusListInProgress;
          } else {
            this.statusList = this.statusListCompleted;
          }
          this.searchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "driverId" },
            {
              status: "Driver Name",
              abbr: "driverName",
            },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
          this.driverSearchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "driverId" },
            {
              status: "Driver Name",
              abbr: "driverName",
            },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
          this.driverSearchItems.forEach((k) => {
            if (k["abbr"] == this.searchTypes.abbr) {
              let elementPos = this.driverSearchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.driverSearchItems.splice(elementPos, 1);
            }
          });
          this.searchItems.forEach((k) => {
            if (k["abbr"] == this.driverSearchTypes.abbr) {
              let elementPos = this.searchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.searchItems.splice(elementPos, 1);
            }
          });
        }
        if (jobStatusVal === "Unassigned") {
          this.search = "";
          this.driverSearchTypes = "";
          this.driverSearch = "";
          this.searchTypes = "";
          this.advanceStatus = "";
          this.assignStatus = "";
          this.searchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
          this.driverSearchItems = [
            {
              status: "Company/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Tranporter ID", abbr: "transporterId" },
            {
              status: "Transporter Name",
              abbr: "Transporter Name",
            },
          ];
          this.driverSearchItems.forEach((k) => {
            if (k["abbr"] == this.searchTypes.abbr) {
              let elementPos = this.driverSearchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.driverSearchItems.splice(elementPos, 1);
            }
          });
          this.searchItems.forEach((k) => {
            if (k["abbr"] == this.driverSearchTypes.abbr) {
              let elementPos = this.searchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.searchItems.splice(elementPos, 1);
            }
          });
        }
        this.paginationURL = "";
        this.tabStatus = jobStatusVal;
        url.push(`status=${jobStatusVal}`);
      } else {
        this.jobStatus = this.tabStatus;
        url.push(`status=${this.tabStatus}`);
      }
    },
    filter_toggle() {
      var x = document.getElementById("filter-toggle");

      if (x.style.display === "block") {
        this.subJobType = "";
        this.subTruckType = "";
        x.style.display = "none";
        this.searchData();
      } else {
        x.style.display = "block";
      }
    },
    searchData(jobStatusVal = null, dateRangeVal = null) {
      if (
        this.subJobType.value == "airFreight" ||
        this.subJobType.value == "seaFreight"
      ) {
        this.subTruckType = "";
      }
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      let timezone = momentTz.tz.guess(); //not used
      let url = [];
      url.push(`workingCountry=${this.workingCountry}`);

      url.push(`isSearch=true`);
      this.getsearchTypeList(this.tabStatus);
      this.searchTabStatus(jobStatusVal, url);
      if (this.subJobType.value) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`jobType=${this.subJobType.value}`);
      }
      if (this.subTruckType) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`truckType=${this.subTruckType}`);
      }
      if (this.assignStatus) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`assignmentStatus=${this.assignStatus}`);
      }
      if (this.advanceStatus) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`advanceStatus=${this.advanceStatus}`);
      }

      if (this.search.trim().length > 0 && this.searchTypes) {
        localStorage.setItem("searchAssign", this.search);
        url.push(`searchType=${this.searchTypes.abbr}`);
        url.push(`searchText=${this.search}`);
      } else if (this.searchTypes) {
        this.loading = false;
        if (!localStorage.getItem("searchAssign")) {
        } else {
        }
        this.search = "";
      }

      if (dateRangeVal) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      } else if (this.startDate.length > 0) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      }

      if (this.driverSearch.trim().length > 0 && this.driverSearchTypes) {
        this.isDashboard = false;
        localStorage.setItem("searchAssignDriver", this.driverSearch);
        url.push(`searchTextTwo=${this.driverSearch}`);
        url.push(`searchTypeTwo=${this.driverSearchTypes.abbr}`);
      } else if (this.driverSearchTypes) {
        this.loading = false;
        this.isDashboard = true;
        if (!localStorage.getItem("searchAssignDriver")) {
        } else {
        }
        this.driverSearch = "";
      }

      let finalUrl = "/dmsAdmin/AssignmentListingView?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";
      this.paginationURL = finalUrl;
      if (
        localStorage.getItem("searchAssign") ||
        localStorage.getItem("searchAssignDriver") ||
        this.subJobType ||
        this.advanceStatus ||
        this.assignStatus ||
        this.subTruckType ||
        jobStatusVal ||
        dateRangeVal
      ) {
        this.getListCount();
      } else {
      }
    },
    reset() {
      if (!this.dateRange2) {
        this.getListCount();
      }
      if (this.tabStatus === "In-progress" || "Completed") {
        this.assignStatus = "";

        if (this.tabStatus === "In-progress") {
          this.statusList = this.statusListInProgress;
        } else if (this.tabStatus === "Completed") {
          this.statusList = this.statusListCompleted;
        }
        this.driverSearchItems = this.searchItems = [
          {
            status: "Company/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "driverId" },
          {
            status: "Driver Name",
            abbr: "driverName",
          },
          { status: "Truck Number", abbr: "truckNumber" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];
      }
      localStorage.removeItem("paginationUrlAssignment");
      localStorage.removeItem("searchAssign");
      localStorage.removeItem("searchAssignDriver");

      if (this.tabStatus === "Unassigned") {
        this.driverSearchItems = this.searchItems = [
          {
            status: "Company/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];
      }
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.xerror = "";
        }, 3000);
        return;
      }
      this.jobStatus = this.tabStatus;
      this.assignStatus = "";
      this.advanceStatus = "";
      this.startDate = "";
      this.select = { status: "", abbr: "" };
      this.selects = { status: "", abbr: "" };
      this.status = "";
      this.dateRange2 = null;
      this.subJobType = "";
      this.subTruckType = "";
      this.dateRange2 = null;
      this.startDate = "";
      this.endDate = "";
      this.executive = "";
      this.search = "";
      this.driverSearchTypes = "";
      this.isDashboard = true;
      this.driverSearch = "";
      this.searchTypes = "";
      this.paginationURL = `/dmsAdmin/AssignmentListingView?workingCountry=${this.workingCountry}&isSearch=true&status=${this.jobStatus}`;

      return;
    },
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#filter_data_toggle .flex.xs2.pr-2 > div {
  padding-top: 0 !important;
}
.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}

.searchAccounts .theme--light.v-label {
  left: 10px !important;
}
.searchAccounts input {
  padding-left: 10px !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
#filter-toggle {
  display: none;
}
.pd-left {
  padding-left: 5px !important;
}
.pd-t-l {
  padding-left: 5px;
  padding-top: 7px;
}
.viewDetail {
  cursor: pointer;
}
.job-detail {
  margin: auto 0;
}
.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.text-center {
  text-align: center !important;
}

.date-picker {
  min-width: 100%;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}

.p-16 {
  padding: 0 16px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.minHeight {
  min-height: 300px;
}
.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.spacerclass {
  max-height: 550px !important;
}
.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.button-red {
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  border: 2px solid red !important;
  margin-top: 5px !important;
  margin-left: 25px !important;
}
.font_Bold {
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
  font-family: "Open Sans bold", sans-serif;
}
.orange-text {
  color: orange;
}
.red-text {
  color: red;

  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}
</style>
