<template>
  <div class="log">
    <button
      @click="
        getResponse();
        assigments();
      "
      class="view-ass-btn"
      over
    >
      Driver Response
    </button>

    <v-dialog v-model="dialog" max-width="65%" persistent>
      <v-form ref="form" onSubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title class="pd5">Responses of Driver</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click.prevent="dialog = false" @click="closepopup">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <vue-countdown-timer
            class="timer-style"
            @start_callback="startCallBack('event started')"
            @end_callback="endCallBack('event ended')"
            :start-time="this.currentDate"
            :end-time="this.bidD.closedDate"
            :interval="1000"
            :start-label="'Time left:'"
            :end-label="'Time left'"
            label-position="begin"
            :end-text="'Bid is closed'"
            :day-txt="'Days/Day '"
            :hour-txt="'    : '"
            :minutes-txt="': '"
            :seconds-txt="'(HH:MM:SS)'"
          >
            <template slot="countdown" slot-scope="scope">
              <span>{{ scope.props.days }}</span>
              <i v-if="scope.props.days > 1">days</i>
              <i v-else>day</i>
              <span>{{ scope.props.hours }}</span>
              <i>{{ scope.props.hourTxt }}</i>
              <span>{{ scope.props.minutes }}</span>
              <i>{{ scope.props.minutesTxt }}</i>
              <span>{{ scope.props.seconds }}</span>
              <i>{{ scope.props.secondsTxt }}</i>
            </template>
          </vue-countdown-timer>
          <v-layout>
            <v-flex xs3 style="padding-left: 20px" class="py-1">
              <v-select
                :items="AssignmentId"
                label="Assignment Id"
                item-text="assignmentId"
                item-value="_id"
                v-model="ids"
                @change="getList"
                class="pt-0 currencyTitle"
                single-line
              ></v-select>
            </v-flex>
            <v-flex xs3>
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs3></v-flex>
            <v-flex xs3 style="padding-left: 30px">
              <v-btn
                @click="goToProfile(bidD.jobId)"
                target="_blank"
                color="orange darken-3"
                class="white--text"
                >View and Accept Bid</v-btn
              >
            </v-flex>
          </v-layout>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>

          <v-card-text class="nospace">
            <v-spacer class="spacerclass">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                :items="items.list"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.drivers[0].driverId }}</td>

                  <td>{{ props.item.drivers[0].name }}</td>

                  <td>
                    {{ props.item.bidAmount }} {{ props.item.bidCurrency }}
                  </td>
                  <td>{{ getTime(props.item.bidDate) }}</td>
                  <td>
                    {{ props.item.advanceAmount }} {{ props.item.bidCurrency }}
                  </td>
                  <td v-if="props.item.status == 'PENDING'">
                    <span class="pending">{{ props.item.status }}</span>
                  </td>
                  <td v-else-if="props.item.status == 'ACCEPTED'">
                    <span class="accepted">{{ props.item.status }}</span>
                  </td>
                  <td v-else-if="props.item.status == 'REJECTED'">
                    <span class="rejected">{{ props.item.status }}</span>
                  </td>
                  <td v-else>
                    <span class="assigned">{{ props.item.status }}</span>
                  </td>
                </template>
              </v-data-table>
            </v-spacer>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>
<script>
import Bidpopup from "@/components/Procurement/Bidpopup";
import ErrorBox from "@/components/Common/ErrorBox";
import { sendBidpopupDriverResponseList } from "@/constants/datatable-headers.js";
import moment from "moment";
import { EventBus } from "../../event-bus.js";
import { bidList, tenderSectionApiUrl } from "../../constants/api-urls";
import { StorageKeys } from "../../constants/constants";

export default {
  name: "Timer",
  components: {
    Bidpopup,
    ErrorBox,
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  data() {
    return {
      AssignmentId: [],
      ids: "",
      currentDate: new Date().toLocaleString(),
      error: "",
      loading: false,
      x: {
        error: null,
      },
      is_accepted: false,
      items: [],
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headers: sendBidpopupDriverResponseList,
    };
  },
  props: {
    bidD: Object,
  },
  created() {
    this.$emit("response-jobD", this.bidD);
  },

  methods: {
    reset() {
      if (this.ids) {
        this.ids = "";
        this.getResponse();
      }
    },
    emitassignid() {
      EventBus.$emit("assign-id", this.ids);
    },
    closepopup() {
      this.ids = "";
    },
    getList() {
      EventBus.$emit("assign-id", this.ids);
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.dialog = true;
      this.loading = true;
      let url = bidList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.ids,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.items = response.data.data;

          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Assignments";

          this.loading = false;
        }
      );
    },
    goToProfile(jobId) {
      if (this.ids) {
        this.$router.push(`/driverfilters/${jobId}` + "?x=" + this.ids);
      } else {
        this.$router.push(
          `/driverfilters/${jobId}` + "?x=" + this.AssignmentId[0]._id
        );
      }
    },
    getid() {},
    assigments() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.dialog = true;

      this.loading = true;
      const data = {
        JobId: this.bidD.jobId.toString(),
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/assignments2`, data, config).then(
        (response) => {
          const { data } = response.data;

          this.count = response.data.data.count;
          this.AssignmentId = response.data.data.data;

          this.loading = false;
        },
        (error) => {
          this.error = error.response.data.message;
          this.dailog = false;
          this.loading = false;
        }
      );
    },
    getTime(time) {
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    startCallBack: function(x) {
      this.loading = false;
    },
    endCallBack: function(x) {
      this.loading = false;
    },
    getResponse() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      (this.loading = "true"), this.$emit("response-jobD", this.bidD);
      this.dialog = true;
      let url = tenderSectionApiUrl.viewBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.bidD._id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.items = response.data.data;

          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Assignments";
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.v-dialog {
  overflow-y: auto !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;

  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
/* .track-ass-btn[data-v-3cb02aee] {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
  margin-right: 5px;
} */
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.max {
  max-width: 70%;
}
.fix-width-td {
  max-width: 200px;
  word-break: break-all;
}
.assigned {
  background-color: orange !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.pending {
  background-color: grey !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
}
.rejected {
  background-color: red !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.accepted {
  background-color: green;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}

.flexx {
  display: flex;
}
.pd5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.timer-style {
  text-align-last: center;
  font-size: large;
}

.spacerclass {
  max-height: 380px !important;
}
</style>
